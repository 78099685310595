* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: sans-serif;
}

:root {
  --black: #000;
  --white: #fff;
  --grey: #808080;
}

html, body {
  overflow-x: hidden;
}

body{
  background-color: var(--black);
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  scroll-behavior: smooth;
  color: var(--white);
}

/* Admin login */

.admin_login_form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 40%;
  min-width: 300px;
  margin: 0 auto;
  padding-top: 80px;
}

.pass-subheading {
  position: absolute;
  top: 20px;
  left: 100px;
  color: var(--white);
}

.admin_login_form figure, .side-bar figure {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 160px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 50px;
}
.admin_login_form img, .side-bar img{
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
}

.admin_login_form input {
  width: 100%;
  height: 50px;
  border-radius: 10px;
  border: 1px solid grey;
  outline: none;
  text-indent: 10px;
  background-color: transparent;
  color: var(--white);
}
.admin_login_form input::placeholder {
  color: rgb(180, 180, 180);
}

.admin_login_form .pass-space {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  border: 1px solid grey;
  border-radius: 10px;
  padding: 0 20px 0 0;
  margin-top: 20px;
}
.admin_login_form .pass-space input{
  border: 0;
  width: 100%;
}

.admin_login_form button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  background-color: transparent;
  outline: none;
  font-size: 1.3rem;
  color: #E4BB89;
  border: 2px solid #E4BB89;
  margin-top: 30px;
}
.admin_login_form button:hover {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  background-color: transparent;
  outline: none;
  font-size: 1.3rem;
  color: var(--black);
  background-color: #E4BB89;
  border: 2px solid #E4BB89;
  margin-top: 30px;
}

.pass-link {
  display: block;
  width: 100%;
  color: #55d5ff;
  text-decoration: underline;
  text-align: right;
}

/* Admin login end */




/* sidebar */

.side-bar {
  display: flex;
  flex-direction: column;
  padding: 50px 10px;
  width: 15%;
  min-width: 200px;
  height: 100vh;
  overflow-y: auto;
  position: sticky;
  top: 0px;
  background-color: white;
}
.side-bar::-webkit-scrollbar {
  display: none;
}
.dp-space {
  width: fit-content;
  margin: 0 auto;
  text-align: center;
}

.dp-space figure {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 20px;
}.dp-space img{
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
}

.side-bar section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}
.side-bar section div {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px;
  width:  100px;
  height: 100px;
  border-radius: 50%;
  border: 2px solid var(--black);
  color: var(--black);
  transition: .5s;
}

.side-bar section div:hover {
  transform: scale(.9);
  cursor: pointer;
}

.sidebar-indicator {
  background-color: var(--black);
  color: #E4BB89 !important;
}


h1 {
  columns: red;
}


/* sidebar end */





/* cms admin page */

.portfolio-page {
  display: flex;
  position: absolute;
  top: 0px;
  height: fit-content;
}

.portfolio-page main {
  padding: 50px 0% 50px 5%;
  width: 100%;
  min-width: 1100px;
}


.portfolio-page main .add-content-btn {
  padding: 10px 20px;
  width: fit-content;
  height: fit-content;
  text-align: center;
  background-color: var(--white);
  color: var(--black);
  font-size: 1.3rem;
  font-weight: 600;
  margin: 0 0 0 auto;
  cursor: pointer;
  margin-bottom: 50px;
  position: relative;
  right: 75px;
}


.search-input {
  display: block;
  width: 40%;
  min-width: 100px;
  height: 50px;
  padding: 0 10px;
  border: 1px solid rgb(255, 255, 255);
  outline: none;
  text-indent: 10px;
  margin-bottom: 10px;
  background-color: transparent;
  color: var(--white);
  margin: 0 auto;
  margin-bottom: 50px;
}
.search-input::placeholder {
  color: rgb(255, 255, 255);
}

.portfolio-contents {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}

.portfolio-content{
  position: relative;
  width: 270px;
}

.portfolio-content:hover .pc-text-name {
  color: #E4BB89;
}

.pc-image {
  height: 10rem;
  margin-bottom: 10px;
  border: 1px solid;
}

.pc-image img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.pc-text-name {
  cursor: pointer;
}

.cancel-pc-item {
  position: absolute;
  top: 10px;
  right: 10px;
  display: block;
  padding: 10px 0 0 14px;
  box-shadow: 0 0 5px 2px rgb(161, 161, 161);
  width: 40px;
  height: 40px;
  background-color: var(--white);
  color: red;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 1.2rem;
  cursor: pointer;
  border-radius: 50%;
  transform: scale(.7);
}


.modal-bg, .modal2-bg, .wcs-modal-bg, .pd-modal-bg{
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  inset: 0px;
  max-height: 100vh;
  background-color: rgba(128, 128, 128, 0.603);
  /* overflow-y: scroll; */
}


.portfoliopage-modal, .portfoliopage-modal2, .wcs-modal, .pd-modal {
  padding: 20px 50px;
  background-color: rgb(255, 255, 255);
  width: 50%;
  min-width: 350px;
  max-height: 90vh;
  overflow-y: auto;
}

.ppm-form, .ppm-form2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

h3 {
  text-align: center;
  color: var(--black);
  margin-bottom: 30px;
}

textarea {
  color: var(--black) !important;
}



.ppm-form :where(input,button) {
  display: block;
  border: 1px solid black;
  outline: none;
  color: var(--black);
  background-color: transparent;
}
.ppm-form2 :where(input,button) {
  display: block;
  border: 1px solid black;
  outline: none;
  color: var(--black);
  background-color: transparent;
}

.ppm-form input, .ppm-form2 input{
  width: 100%;
  padding: 0 10px;
  margin-bottom: 10px;
  height: 30px;
}

.ppm-form button, .ppm-form2 button{
  font-size: 1.2rem;
  border-radius: 50%;
  font-size: .9rem;
  width: 80px;
  height: 80px;
  margin-top: 20px;
  cursor: pointer;
}

textarea {
  font-size: .8rem;
  width: 100%;
  padding: 10px;
  height: 70px;
  margin-bottom: 20px;
  border: 1px solid black;
}

.ppm-upload {
  width: 100%;
  height: 50px;
  border: 2px solid black;
  padding: 10px 30px 30px 0;
}

.ppmu {
  border: none;
}


.listing-space {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
  border-bottom: 1px solid var(--black);
  padding-bottom: 5px;
}

.listing-space :where(.sftw-first-part,.sftw-second-part) {
  display: flex;
  gap: 10px;
}

.sftw-first-part .sftw-add-soft-btn {
  display: flex;
  align-items: center;
  min-width: fit-content;
  height: 30px;
  padding: 0 20px;
  background-color: black;
  color: white;
  cursor: pointer;
}

.sftw-second-part {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.sftw-second-part span {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  gap: 5px;
  background-color: green;
  color: var(--white);
}
.sftw-second-part span img {
  width: 20px;
  max-width: 20px;
  height: fit-content;
  max-height: fit-content;
  cursor: pointer;
}

/* second part */
.listing-space2 {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
  border-bottom: 1px solid var(--black);
  padding-bottom: 5px;
}

.listing-space2 :where(.skill-first-part,.skill-second-part) {
  display: flex;
  gap: 10px;
}

.skill-first-part .skill-add-soft-btn {
  display: flex;
  align-items: center;
  min-width: fit-content;
  height: 30px;
  padding: 0 20px;
  background-color: black;
  color: white;
  cursor: pointer;
}

.skill-second-part {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.skill-second-part span {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  gap: 5px;
  background-color: green;
  color: var(--white);
}
.skill-second-part span img {
  width: 20px;
  max-width: 20px;
  height: fit-content;
  max-height: fit-content;
  cursor: pointer;
}



.categories {
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.categories::-webkit-scrollbar {
  display: none;
}

.products-category-list {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 200px;
}

.products-category-list a {
  display: block;
  width: fit-content;
  border: 2px solid var(--white);
  color: var(--white);
  text-decoration: none;
  padding: 10px 20px;
}
.products-category-list a:hover {
  background-color: var(--white);
  color: var(--black);
}

.p-category {
  padding: 50px;
}

.products-subheading {
  padding-bottom: 10px;
  border-bottom: 2px solid var(--grey);
  text-align: center;
}

.back-btn {
  display: block;
  margin: 20px 0 50px 0;
  color: var(--white);
  font-style: italic;
}

.p-category .add-content-btn {
  padding: 10px 20px;
  width: fit-content;
  height: fit-content;
  text-align: center;
  background-color: var(--white);
  color: var(--black);
  font-size: 1.3rem;
  font-weight: 600;
  margin: 0 0 0 auto;
  cursor: pointer;
  margin-bottom: 50px;
}


.what-client-says, .product-details {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 200px;
  height: 30px;
  padding: 0  10px;
  background-color: var(--white);
  color: var(--black);
  cursor: pointer;
  width: fit-content;
}

.what-client-says {
  transform: translate(-50%, -130%);
}

.product-details {
  transform: translate(-50%, -0%);
}
/* cms admin pages combined end */





/* changes */

  .form-subheading {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      gap: 20px;
  }

  .form-subheading h2 {
      min-width: fit-content;
      color: var(--black);
  }

  .form-subheading .fs-line {
      width: 100%;
      height: 2px;
      background-color: #000;
  }


  .form-input-sec {
      margin: 30px 0 0 0;
      min-width: 100%;
  }
  .section-container {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-bottom: 30px;
      min-width: 100%;
  }

  .section-container .manipulation-btns {
      display: flex;
      gap: 15px;
  }
  .section-container .manipulation-btns span{
      /* display: flex;
      justify-content: center;
      align-items: center; */
      padding: 10px 20px;
     font-size: 1rem;
     font-weight: 600;
     border-radius: 10px;
     cursor: pointer;
  }
  .section-container .manipulation-btns span:nth-of-type(1){
      background-color: black;
      color: white;
  }


  .section-container .manipulation-btns span{
      background-color: red; 
  }

  .file-section {
      display: flex;
      align-items: center;
      gap: 10px;
      border: 1px solid var(--black);
      padding: 10px;
      margin-bottom: 10px;
  }
  .file-section p {
      min-width: fit-content;
      color: var(--black);
      font-size: .7rem;
  }

  .file-section input {
      border: none;
      margin: 0;
  }

  .dlt-single-file-section {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    min-width: 30px;
    min-height: 30px;
    border-radius: 50%;
    border: 2px solid red;
    color: red;
    font-family: Arial, Helvetica, sans-serif;
    cursor: pointer;
  }

  .form-btns {
      display: flex;
      gap: 30px;
  }
  .form-btns button:nth-of-type(1) {
      color: black;
      background-color: white;
      border-color: black;
  }
  .form-btns button:nth-of-type(2) {
      color: #E4BB89;
      background-color: var(--black);
  }

  .delete-modal-bg {
      display: none;
  }

  .delete-modal-bg.active {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 600px;
      height: 300px;
      padding: 20px;
      gap: 50px;
      background-color: var(--black);
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      box-shadow: 0 0 10px 5px rgb(39, 39, 39);
      text-align: center;
  }

  .dm-btns{
     display: flex;
     align-items: center;
     gap: 50px;
  }

  .dm-btns span {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100px;
      height: 100px;
      border-radius: 50px;
      border: 2px solid;
      cursor: pointer;
  }
  .dm-btns span:nth-of-type(1) {
      border-color: red;
      color: red;
  }
  .dm-btns span:nth-of-type(2) {
      border-color: #E4BB89;
      color: #E4BB89;
  }

/* changes end */


.portfolio-select {
  margin: 0 auto 0 0;
  height: 40px;
  margin-bottom: 40px;
  border: 2px solid black;
  padding: 0 20px;
}


.table-space {
  max-height: 80vh;
  overflow-y: auto;
  margin-top: 50px;
}

table {
  border-collapse: collapse;
  width: 100%;
}
table thead{
  border-top: 2px solid var(--white);
  border-bottom: 2px solid var(--white);
}

table th {
  padding: 20px 10px;
  text-align: left;
}
table td {
  padding: 20px 10px;
  border: 0px;
  cursor: pointer;
}

table tr {
  background-color: rgb(48, 48, 48);
}

table thead th:nth-of-type(1),
table tr td:nth-of-type(1) {
  text-align: center;
} 


.ps-modal-bg {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  inset: 0px;
  max-height: 100vh;
  background-color: rgba(128, 128, 128, 0.603);
}

.ps-modal {
  width: 700px;
  height: 100%;
  display: flex;
  background-color: red;
}

.psm-side1 {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 20px;
  height: 100%;
  justify-content: center;
  background-color: black;
  min-width: 100px;
  width: 200px;
}

.psm-side1 h3 {
  color: #E4BB89;
  text-align: left;
}


.psm-side2 {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  height: 100%;
  background-color: white;
  color: var(--black);
  width: 100%;
}

.pms-subheading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0px 50px 0px;
}

.pms-cancel {
  color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid red;
  font-weight: 700;
  font-family: Arial, Helvetica, sans-serif;
}

.pst-details {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 20px 0 0 0;
}
.pst-details div{
  background-color: #d8d8d8;
  padding: 20px;
  border-radius: 20px;
  font-size: 1.3rem;
}
.pst-details div textarea{
  background-color: transparent;
  border: none;
  outline: none;
}
.pst-details div span{
  padding: 10px;
  font-size: .9rem;
  color: #E4BB89;
  background-color: #000;
  border-radius: 5px;
  margin-right: 10px;
}

.ps-modal-bg {
  display: none;
  /* other styles */
}

.ps-modal-bg.active {
  display: flex;
  /* other styles */
}


.del-iv {
  min-width: fit-content;
  padding: 10px 20px;
  background-color: red;
  border-radius: 10px;
  cursor: pointer;
}



.close-btn-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: rgb(255, 240, 240);
  color: red;
  border: 2px solid red;
  font-size: 1.5rem;
  font-family: Arial, Helvetica, sans-serif;
  width: 50px;
  height: 50px;
  z-index: 1000000;
  border-radius: 50%;
  margin: 0 0 0 auto;
  cursor: pointer;
}


/* media upload */

.upload-banner-pic-space {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: rgb(31, 31, 31);
  width: 600px;
  margin: 0 auto 20px auto;
}

.media-upload-box {
  width: 100%;
  height: 200px;
  max-width: 600px;
  max-height: 200px;
  border: 2px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.media-edit-btn {
  border-radius: 50%;
  border: 0;
  padding: 2rem 1rem;
  font-size: 20px;
  position: absolute;
  cursor: pointer;
  background-color: #ffffffbf;
}

.media-edit-btn input {
  opacity: 0;
  position: absolute;
}

.media-edit-btn p {
  color: var(--black);
}

.media-preview {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.media-input {
  display: block;
  width: 100%;
  height: 100%;
  margin: 20px 0;
  cursor: pointer;
  z-index: 1;
  opacity: 0;
}
.media-placeholder {
  color: #aaa;
  position: absolute;
  z-index: 0;
}

/* media upload */




#btn-design {
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 10px;
  background-color: black;
  color: white;
  cursor: pointer;
  max-height: fit-content;
  height: 50px;
  width: 200px;
  max-width: fit-content;
  margin-bottom: 30px;
}